import axios from "../../axios";
export const GET_CART_DATA = "GET_CART_DATA";
export const UPDATE_CART_DATA = "UPDATE_CART_DATA";

function getCartDataAction(cartData) {
  return {
    type: GET_CART_DATA,
    payload: cartData,
  };
}

export function getCartDataActionCreator() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let accessToken = getState().access_token;
      if (accessToken) {
        axios
          .get("carts", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            let data = response.data.data.items;
            dispatch(getCartDataAction(data));
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            dispatch(getCartDataAction([]));
            return reject();
          });
      }
      dispatch(getCartDataAction([]));
      resolve();
    });
  };
}

export function getArabicCartDataActionCreator() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let accessToken = getState().access_token;
      if (accessToken) {
        axios
          .get("carts", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Accept-Language": "ar",
            },
          })
          .then((response) => {
            let data = response.data.data.items;
            dispatch(getCartDataAction(data));
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            dispatch(getCartDataAction([]));
            return reject();
          });
      }
      dispatch(getCartDataAction([]));
      resolve();
    });
  };
}

function updateCartDataAction(cartData) {
  return {
    type: UPDATE_CART_DATA,
    payload: cartData,
  };
}

export function updateCartDataActionCreator(items) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(updateCartDataAction(items));
      resolve();
      // let accessToken = getState().access_token
      // if (accessToken) {
      //   axios.get('carts', {
      //     headers: {
      //       Authorization: `Bearer ${accessToken}`
      //     }
      //   })
      //     .then(response => {
      //       let data = response.data.data.items
      //       dispatch(updateCartDataAction(data))
      //       return resolve()
      //     })
      //     .catch(err => {
      //       console.log(err)
      //       dispatch(getCartDataAction([]))
      //       return reject()
      //     })
      // }
      // dispatch(getCartDataAction([]))
    });
  };
}
