import { FETCH_BEST_PRODUCTS_SUCCESS } from "../actions/tempBestProductsAction";

function bestProductReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_BEST_PRODUCTS_SUCCESS:
      return { ...action?.bestProducts };
    default:
      return state;
  }
}

export default bestProductReducer;
