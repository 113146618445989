import { GET_ALL_FAVORITES } from '../actions/favorites'


export default function favorites(state = {}, action) {
  switch (action.type) {
    case GET_ALL_FAVORITES:
      return action.payload
    default:
      return state
  }
}