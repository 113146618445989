export const GET_ACCOUNT_ACCESS_TOKEN = "GET_ACCOUNT_ACCESS_TOKEN"


function accessTokenAction(accessToken) {
  return {
    type: GET_ACCOUNT_ACCESS_TOKEN,
    payload: accessToken
  }
}

export function updateAccessToken(accessToken) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(accessTokenAction(accessToken))
      resolve()
    })
  }
}