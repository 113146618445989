export const UPDATE_CART_SUBTOTAL = "UPDATE_CART_SUBTOTAL"


function updateCartSubTotalAction(cartSubTotal) {
  return {
    type: UPDATE_CART_SUBTOTAL,
    payload: cartSubTotal
  }
}

export function updateCartSubTotal(cartSubTotal) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateCartSubTotalAction(cartSubTotal))
      resolve()
    })
  }
}