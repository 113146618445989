import axios from "../../axios";

export const GET_COMMERCIAL_DATA = "GET_COMMERCIAL_DATA";

function getCommercialDataAction(commercialData) {
  return {
    type: GET_COMMERCIAL_DATA,
    commercialData,
  };
}

export function getCommercialData() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get("static_contents")
        .then((response) => {
          let data = response.data.data;
          dispatch(getCommercialDataAction(data));
          return resolve();
        })
        .catch((err) => {
          console.log(err);
          return reject();
        });
    });
  };
}
