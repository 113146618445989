import axios from "../../axios";
export const GET_STATIC_CONTENT = "GET_STATIC_CONTENT";

function getStaticContent(content) {
  return {
    type: GET_STATIC_CONTENT,
    payload: content,
  };
}

export function getStaticContentActionCreator() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios
        .get("static_contents")
        .then((response) => {
          let data = response.data.data;
          dispatch(getStaticContent(data));
          resolve();
        })
        .catch((err) => {
          console.log(err);
          dispatch(getStaticContent({}));
          reject();
        });
    });
  };
}
