import axios from "../../axios";
export const GET_CATEGORIES = "GET_CATEGORIES";

function getCategoriesAction(categories) {
  return {
    type: GET_CATEGORIES,
    categories,
  };
}

// export function getCategories() {
//   return dispatch => {
//     return new Promise((resolve, reject) => {
//       axios.get("categories")
//         .then(response => {
//           let data = response.data.data
//           dispatch(getCategoriesAction(data))
//           resolve()
//         })
//         .catch(err => {
//           console.log(err)
//           reject()
//         })
//     })
//   }
// }

export function getCategories() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const publicData = {
        publicToken:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYWZjZjMxOTQ5MDEyYzJmZWYyMTU2MjcwYWJiYzBmMmNjMDlkYjI4YWY5YmFmYTMzMDk3M2E1MTRhZWE1ZGFhOTg2MjNiYjBjY2M3ODM2ZGYiLCJpYXQiOjE2MjU1MjQ2MjAsIm5iZiI6MTYyNTUyNDYyMCwiZXhwIjoxNjU3MDYwNjIwLCJzdWIiOiIzMTciLCJzY29wZXMiOltdfQ.TZAngdaXkp22AcN_jEKafPzk3mAtP512TQEO84jnZ6TE41qNE17ibIOrWl0c2CtSln7X8w6HRMoNqLLGAO43P9NTxgT0b4NCFF1KQ7CEzVMrSEQR5YF8yruxto06VDnVItARZHQdzHIKpH8Q-5JNbmWkto05i9s0ilcmj9hx-TEU22glDP6duHux-T6Bon8L1JUvYJ883y9OJN4W1CzyI0lVOq7DHmvVFfGrZ357HhJL0dE5vEtcSq9mAusPMRGWruNhBJAppTIV-cYKPuhN-3T45voUh-Lj48AqHJ2H_8_P7NgUm4f6G8vgIIcYS3I-2VIuodjs53DYluDWcg1BueoJ205eI3D3NTeuU-7udEPByhV4-jkvG_2DZPHhJ_1gLs0NRBMdfQ8C8P0h65v2_S3vEx6feq9NYCP0jIcewmeyb-ua46U7ZDKQ_6YtmC4YKfNni8cTavng6pNkrD5195zS4mzrGHmXnf855Iekjdmi54iClXT4u7wSqJpIGYg4uPbLPZlsXtDJ89FSknK9-KNZAeq11GHC-nnfFWyUsDrONEUD-j14uZDWrk6PWRaNreo3fC_HJdcf_gmtXHV97umW4T9kLcbXvaeDvjT4a9R0Mm6HgusSd1sveDszdpLPHnV3SloVGhtrHkFFoTcyZZJhOZDfePbmErjzkyZchvY",
      };

      let accessToken = getState().device?.deviceTokenAccount?.access_token;
      if (accessToken) {
        axios
          .get("home", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            let data = response.data.data.categories;
            dispatch(getCategoriesAction(data));
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      } else {
        axios
          .get("home", {
            headers: {
              Authorization: `Bearer ${publicData.publicToken}`,
            },
          })
          .then((response) => {
            let data = response.data.data.categories;
            dispatch(getCategoriesAction(data));
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      }
    });
  };
}

export function getArabicCategories() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const publicData = {
        publicToken:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiYWZjZjMxOTQ5MDEyYzJmZWYyMTU2MjcwYWJiYzBmMmNjMDlkYjI4YWY5YmFmYTMzMDk3M2E1MTRhZWE1ZGFhOTg2MjNiYjBjY2M3ODM2ZGYiLCJpYXQiOjE2MjU1MjQ2MjAsIm5iZiI6MTYyNTUyNDYyMCwiZXhwIjoxNjU3MDYwNjIwLCJzdWIiOiIzMTciLCJzY29wZXMiOltdfQ.TZAngdaXkp22AcN_jEKafPzk3mAtP512TQEO84jnZ6TE41qNE17ibIOrWl0c2CtSln7X8w6HRMoNqLLGAO43P9NTxgT0b4NCFF1KQ7CEzVMrSEQR5YF8yruxto06VDnVItARZHQdzHIKpH8Q-5JNbmWkto05i9s0ilcmj9hx-TEU22glDP6duHux-T6Bon8L1JUvYJ883y9OJN4W1CzyI0lVOq7DHmvVFfGrZ357HhJL0dE5vEtcSq9mAusPMRGWruNhBJAppTIV-cYKPuhN-3T45voUh-Lj48AqHJ2H_8_P7NgUm4f6G8vgIIcYS3I-2VIuodjs53DYluDWcg1BueoJ205eI3D3NTeuU-7udEPByhV4-jkvG_2DZPHhJ_1gLs0NRBMdfQ8C8P0h65v2_S3vEx6feq9NYCP0jIcewmeyb-ua46U7ZDKQ_6YtmC4YKfNni8cTavng6pNkrD5195zS4mzrGHmXnf855Iekjdmi54iClXT4u7wSqJpIGYg4uPbLPZlsXtDJ89FSknK9-KNZAeq11GHC-nnfFWyUsDrONEUD-j14uZDWrk6PWRaNreo3fC_HJdcf_gmtXHV97umW4T9kLcbXvaeDvjT4a9R0Mm6HgusSd1sveDszdpLPHnV3SloVGhtrHkFFoTcyZZJhOZDfePbmErjzkyZchvY",
      };

      let accessToken = getState().device?.deviceTokenAccount?.access_token;
      if (accessToken) {
        axios
          .get("home", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Accept-Language": "ar",
            },
          })
          .then((response) => {
            let data = response.data.data.categories;
            dispatch(getCategoriesAction(data));
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      } else {
        axios
          .get("home", {
            headers: {
              Authorization: `Bearer ${publicData.publicToken}`,
              "Accept-Language": "ar",
            },
          })
          .then((response) => {
            let data = response.data.data.categories;
            dispatch(getCategoriesAction(data));
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      }
    });
  };
}