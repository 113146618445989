import { GET_ALL_ORDERS } from '../actions/orders'


export default function orders(state = {}, action) {
  switch (action.type) {
    case GET_ALL_ORDERS:
      return action.payload
    default:
      return state
  }
}