import { UPDATE_CART_SUBTOTAL } from '../actions/cartSubTotal'


export default function cartSubTotal(state = 0 , action) {
  switch (action.type) {
    case UPDATE_CART_SUBTOTAL:
      return action.payload
    default:
      return state
  }
}