import { v4 as uuidv4 } from "uuid";
import axios from "../../axios";

export const CREATE_DEVICE_TOKEN = "CREATE_DEVICE_TOKEN";

function deviceToken(deviceToken, deviceTokenAccount) {
  return {
    type: CREATE_DEVICE_TOKEN,
    device: {
      deviceToken,
      deviceTokenAccount,
    },
  };
}

export function handleDeviceToken() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let getDeviceToken = localStorage.getItem("deviceToken");
      if (!getDeviceToken) {
        postDeviceToken(dispatch);
      }

      let localDeviceTokenAccount = localStorage.getItem("deviceTokenAccount");
      if (!localDeviceTokenAccount) {
        axios
          .post("token_login", {
            device_token: getDeviceToken,
          })
          .then((response) => {
            let serverDeviceTokenAccount = response.data.data;
            localStorage.setItem(
              "deviceTokenAccount",
              JSON.stringify(serverDeviceTokenAccount)
            );
            dispatch(deviceToken(getDeviceToken, serverDeviceTokenAccount));
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            return reject();
          });
      }
      dispatch(
        deviceToken(getDeviceToken, JSON.parse(localDeviceTokenAccount))
      );
      resolve();
    });
  };

  function postDeviceToken(dispatch) {
    let getDeviceToken = uuidv4();
    axios
      .post("token_account", {
        device_token: getDeviceToken,
      })
      .then((response) => {
        if (response.data.code === 422) {
          postDeviceToken(getDeviceToken, dispatch);
        }
        let serverDeviceTokenAccount = response.data.data;
        localStorage.setItem("deviceToken", getDeviceToken);
        localStorage.setItem(
          "deviceTokenAccount",
          JSON.stringify(serverDeviceTokenAccount)
        );
        dispatch(deviceToken(getDeviceToken, serverDeviceTokenAccount));
      });
  }
}
