import axios from "../../axios";

export const GET_ALL_ORDERS = "GET_ALL_ORDERS";

function getAllOrdersAction(orders) {
  return {
    type: GET_ALL_ORDERS,
    payload: orders,
  };
}

export function getAllOrdersActionCreator() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let accessToken = getState().access_token;
      if (accessToken) {
        axios
          .get("orders", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            let data = response.data.data;
            dispatch(getAllOrdersAction(data));
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            dispatch(getAllOrdersAction({}));
            return reject();
          });
      }
      dispatch(getAllOrdersAction({}));
      resolve();
    });
  };
}


export function getAllOrdersArabicActionCreator() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let accessToken = getState().access_token;
      if (accessToken) {
        axios
          .get("orders", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Accept-Language": "ar",
            },
          })
          .then((response) => {
            let data = response.data.data;
            dispatch(getAllOrdersAction(data));
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            dispatch(getAllOrdersAction({}));
            return reject();
          });
      }
      dispatch(getAllOrdersAction({}));
      resolve();
    });
  };
}
