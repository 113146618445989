import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import LoadingContainer from "./loadingContainer";

function CheckoutProtect({ component: Component, path, redirectPath, redirectPathSecond }) {
  const isLogged = useSelector((state) => state.isLogged);
  const cartSubTotal = useSelector((state) => state.cartSubTotal);
  return (
    // <LoadingContainer>
    <>
      {isLogged && cartSubTotal >= 500 ? (
        <Route path={path}>
          <Component />
        </Route>
      ) : (
        <Redirect to={`${isLogged && cartSubTotal >= 500 ? redirectPathSecond : redirectPath}`} />
      )}
    </>
    // </LoadingContainer>

  );
}

export default CheckoutProtect;
