import axios from "../../axios";

export const GET_HOME_DATA = "GET_HOME_DATA";

function homeDataActionCreator(homeData) {
  return {
    type: GET_HOME_DATA,
    payload: homeData,
  };
}

export function getHomeData() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let accessToken = getState().device.deviceTokenAccount.access_token;
      if (accessToken) {
        axios
          .get("home", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            let homeData = response.data.data;
            dispatch(homeDataActionCreator(homeData));
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      }
    });
  };
}
