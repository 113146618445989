import currencyReducer from "./currencyReducer";
import productReducer from "./productReducer";
import wishlistReducer from "./wishlistReducer";
import compareReducer from "./compareReducer";
import deviceToken from "./deviceToken";
import homeData from "./homeData";
import isLoading from "./isLoading";
import commercialData from "./commercialData";
import productsCategories from "./productsCategories";
import facebook from "./fbInit";
import google from "./googleInit";
import isLogged from "./isLogged";
import access_token from "./accessToken";
import fcmToken from "./fcmToken";
import googleAuth from "./googleAuth";
import userData from "./userData";
import staticContent from "./staticContent";
import favorites from "./favorites";
import cartData from "./cartData";
import orders from "./orders";
import bestProductReducer from "./tempBestProductReducer";
import cartSubTotal from "./cartSubTotal";
import areas from "./areas";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "en" }),
  currencyData: currencyReducer,
  productData: productReducer,
  wishlistData: wishlistReducer,
  compareData: compareReducer,
  device: deviceToken,
  homeData,
  tempBestSelling: bestProductReducer,
  isLoading,
  commercialData,
  productsCategories,
  facebook,
  google,
  access_token,
  isLogged,
  areas,
  fcmToken,
  googleAuth,
  userData,
  staticContent,
  favorites,
  cartData,
  cartSubTotal,
  orders,
});

export default rootReducer;
