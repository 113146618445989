import PropTypes from "prop-types";
// import React, { useEffect, useCallback } from "react";
import React, { useEffect, Suspense } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { useSelector } from "react-redux";
import { LastLocationProvider } from "react-router-last-location";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { handleDeviceToken } from "./redux/actions/deviceToken";
import { getHomeData } from "./redux/actions/homeData";
import {
  getCategories,
  getArabicCategories,
} from "./redux/actions/productsCategories";
import { getFbSdk } from "./redux/actions/facebook";
import { getGoogleSdk } from "./redux/actions/google";
import {
  getAllFavoritesActionCreator,
  getAllArabicFavoritesActionCreator,
} from "./redux/actions/favorites";
import {
  getCartDataActionCreator,
  getArabicCartDataActionCreator,
} from "./redux/actions/cartData";
import {
  getAllOrdersActionCreator,
  getAllOrdersArabicActionCreator,
} from "./redux/actions/orders";
// import ProtectRoute from "./pages/other/protected-route";
// import ReverseProtectRoute from "./pages/other/reverse-protected-route";
import CheckoutProtect from "./pages/other/checkout-protect";
import { initHandle } from "./redux/actions/shared";
// import { messaging } from "./firebase";
// import { handleUpdateFcmToken } from "./redux/actions/fcmToken";
import { automateUserLoginAction } from "./redux/actions/userData";
import { getStaticContentActionCreator } from "./redux/actions/staticContent";
import { isloadingHandle } from "./redux/actions/loading";
import Wishlist from "./pages/other/Wishlist";
// import ThankYou from './pages/other/thank-you';
// import MyAccount from './pages/other/MyAccount';
// import LoginRegister from './pages/other/LoginRegister';

const HomeCakeShop = React.lazy(() => import('./pages/home/HomeCakeShop'));
const ShopGridStandard = React.lazy(() => import('./pages/shop/ShopGridStandard'));
const Product = React.lazy(() => import('./pages/shop-product/Product'));
const Contact = React.lazy(() => import('./pages/other/Contact'));
const About = React.lazy(() => import('./pages/other/About'));
const ProtectRoute = React.lazy(() => import('./pages/other/protected-route'));
const ReverseProtectRoute = React.lazy(() => import('./pages/other/reverse-protected-route'));
const ThankYou = React.lazy(() => import('./pages/other/thank-you'));
const MyAccount = React.lazy(() => import('./pages/other/MyAccount'));
const LoginRegister = React.lazy(() => import('./pages/other/LoginRegister'));
const Cart = React.lazy(() => import('./pages/other/Cart'));
const Orders = React.lazy(() => import('./pages/other/orders'));
const Checkout = React.lazy(() => import('./pages/other/Checkout'));
const NotFound = React.lazy(() => import('./pages/other/NotFound'));



export const TranslationsContext = React.createContext();
function App(props) {
  const { dispatch } = props;
  const device = useSelector((state) => state.device);
  const accessToken = useSelector((state) => state.access_token);
  const currentLanguageCode = useSelector(
    (state) => state.multilanguage.currentLanguageCode
  );

  const favoritesCount = useSelector((state) => state.favorites.total_count);

  const cartSubTotal = useSelector((state) => state.cartData.cartSubTotal);
  const translations = props.strings;

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
  }, []);

  // const fcmToken = useCallback(() => {
  //   messaging
  //     .requestPermission()
  //     .then(() => {
  //       return messaging.getToken();
  //     })
  //     .then((token) => {
  //       dispatch(handleUpdateFcmToken(token));
  //       localStorage.setItem("fcm_token", token);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //     let fcmToken = localStorage.getItem("fcm_token");
  //     console.log(localStorage);
  //     if (fcmToken) {
  //       return dispatch(handleUpdateFcmToken(fcmToken));
  //     }
  // }, [dispatch]);

  /***************************/
  // messaging
  // .requestPermission()
  // .then(() => {
  //   return messaging.getToken();
  // })
  // .then(() => {
  //   console.log("Have Permission")
  //   return messaging.getToken();
  // })
  // .then((token) =>{
  //   console.log(token);
  // })
  // .catch((err) => {
  //   console.log(err);
  // });

  /************************** */

  useEffect(() => {
    dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json"),
          ar: require("./translations/arabic.json"),
        },
      })
    );
  });

  // useEffect(() => {
  //   dispatch(isloadingHandle(true));
  //   Promise.all([
  //     dispatch(handleDeviceToken()),
  //     dispatch(getFbSdk()),
  //     dispatch(getCategories()),
  //     dispatch(initHandle()),
  //     dispatch(getGoogleSdk()),
  //     dispatch(automateUserLoginAction()),
  //     dispatch(getStaticContentActionCreator()),
  //     dispatch(getCartDataActionCreator()),
  //     fcmToken(),
  //   ]).finally(() => {
  //     dispatch(isloadingHandle(false));
  //   });
  // }, [dispatch, fcmToken]);

  useEffect(() => {
    dispatch(isloadingHandle(true));
    Promise.all([
      dispatch(handleDeviceToken()),
      dispatch(getFbSdk()),
      // dispatch(getCategories()),

      dispatch(initHandle()),
      dispatch(getGoogleSdk()),
      dispatch(automateUserLoginAction()),
      dispatch(getStaticContentActionCreator()),
    ]).finally(() => {
      dispatch(isloadingHandle(false));
    });
  }, [dispatch, currentLanguageCode]);

  useEffect(() => {
    dispatch(isloadingHandle(true));
    if (currentLanguageCode === "ar") {
      Promise.all([
        dispatch(getArabicCategories()),
        dispatch(getArabicCartDataActionCreator()),
      ]).finally(() => {
        dispatch(isloadingHandle(false));
      });
    } else {
      dispatch(getCategories());
      Promise.all([
        dispatch(getCategories()),
        dispatch(getCartDataActionCreator()),
      ]).finally(() => {
        dispatch(isloadingHandle(false));
      });
    }
  }, [dispatch, currentLanguageCode, cartSubTotal]);

  useEffect(() => {
    if (
      device?.deviceTokenAccount?.access_token &&
      currentLanguageCode === "ar"
    ) {
      dispatch(getAllArabicFavoritesActionCreator());
    }
  }, [dispatch, device, currentLanguageCode, favoritesCount]);

  useEffect(() => {
    if (
      device?.deviceTokenAccount?.access_token &&
      currentLanguageCode === "en"
    ) {
      dispatch(getAllFavoritesActionCreator());
    }
  }, [dispatch, device, currentLanguageCode, favoritesCount]);

  useEffect(() => {
    if (device?.deviceTokenAccount?.access_token) {
      dispatch(getHomeData());
    }
  }, [dispatch, device]);

  useEffect(() => {
    if (accessToken && currentLanguageCode === "en") {
      dispatch(getAllOrdersActionCreator());
    }
  }, [dispatch, accessToken, currentLanguageCode]);

  useEffect(() => {
    if (accessToken && currentLanguageCode === "ar") {
      dispatch(getAllOrdersArabicActionCreator());
    }
  }, [dispatch, accessToken, currentLanguageCode]);

  return (
    <div>
      <ToastProvider
        placement={`${currentLanguageCode === "ar" ? "bottom-right" : "bottom-left"
          }`}
      >
        <TranslationsContext.Provider value={translations}>
          <BreadcrumbsProvider>
            <Router basename={process.env.PUBLIC_URL}>
              <LastLocationProvider>
                <ScrollToTop>
                  {/* <LoadingContainer> */}
                  <Switch>
                    <Route exact path="/">
                      <HomeCakeShop />
                    </Route>

                    {/* Shop pages */}
                    <Route path="/shop">
                      <ShopGridStandard />
                    </Route>

                    {/* Shop product pages */}
                    <Route
                      path="/product/:id"
                      render={(routeProps) => (
                        <Product
                          {...routeProps}
                          key={routeProps.match.params.id}
                        />
                      )}
                    />

                    {/* Other pages */}

                    <Route path="/contact">
                      <Contact />
                    </Route>

                    <Route path="/about">
                      <About />
                    </Route>

                    <ProtectRoute
                      path="/thank-you"
                      component={ThankYou}
                      redirectPath="/login-register"
                    />

                    <ProtectRoute
                      path="/my-account"
                      component={MyAccount}
                      redirectPath="/login-register"
                    />

                    <ReverseProtectRoute
                      path="/login-register"
                      component={LoginRegister}
                    />

                    <ProtectRoute
                      path="/cart"
                      component={Cart}
                      redirectPath="/login-register"
                    />

                    <ProtectRoute
                      path="/orders"
                      component={Orders}
                      redirectPath="/login-register"
                    />

                    <Route path="/wishlist" component={Wishlist} />

                    <CheckoutProtect
                      path="/checkout"
                      component={Checkout}
                      redirectPath="/login-register"
                      redirectPathSecond="/cart"
                    />

                    <Route exact component={NotFound} />
                  </Switch>
                  {/* </LoadingContainer> */}
                </ScrollToTop>
              </LastLocationProvider>
            </Router>
          </BreadcrumbsProvider>
        </TranslationsContext.Provider>
      </ToastProvider>
    </div>
  );
}

App.propTypes = {
  dispatch: PropTypes.func,
};

export default multilanguage(App);
