import axios from "../../axios";

export const GET_USER_DATA = "GET_USER_DATA";
export const AUTOMATE_USER_LOGIN = "AUTOMATE_USER_LOGIN";
export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS";
export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS";
export const UPDATE_USER = "UPDATE_USER";
export const GET_ALL_ADDRESS = "GET_ALL_ADDRESS";

function userDataActionCreator(userData) {
  return {
    type: GET_USER_DATA,
    payload: userData,
  };
}

export function getUserData(userData) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(userDataActionCreator(userData));
      resolve();
    });
  };
}

function automateUserLogin(userData) {
  return {
    type: AUTOMATE_USER_LOGIN,
    payload: userData,
  };
}

export const signout = () => (dispatch) => {
  localStorage.removeItem("access_token");
  // dispatch({ type: USER_SIGNOUT });
  return true;
};

export function automateUserLoginAction() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      let access_token = localStorage.getItem("access_token");
      if (access_token) {
        Promise.all([
          axios.get("user", {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }),
          axios.get("addresses", {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }),
        ])
          .then(([userData, addressesData]) => {
            let user = userData.data.data;
            let addresses = addressesData.data.data;
            dispatch(automateUserLogin({ user, addresses }));
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            dispatch(automateUserLogin({}));
            return reject();
          });
      }
      dispatch(automateUserLogin({}));
      resolve();
    });
  };
}

function getAllAddressesAction(addresses) {
  return {
    type: GET_ALL_ADDRESS,
    payload: addresses,
  };
}

export function getAllAddressesActionCreator(accessToken) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (accessToken) {
        axios
          .get("addresses", {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            let addresses = response.data.data;
            dispatch(getAllAddressesAction(addresses));
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            dispatch(getAllAddressesAction([]));
            return reject();
          });
      }
      dispatch(getAllAddressesAction([]));
      resolve();
    });
  };
}

function updateUserAddressesActionCreator(addresses) {
  return {
    type: UPDATE_USER_ADDRESS,
    payload: addresses,
  };
}

export function updateUserAddress(addresses) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(updateUserAddressesActionCreator(addresses));
      resolve();
    });
  };
}

function addUserAddressActionCreator(address) {
  return {
    type: ADD_USER_ADDRESS,
    payload: address,
  };
}

export function addUserAddress(address) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(addUserAddressActionCreator(address));
      resolve();
    });
  };
}

function updateUserActionCreator(user) {
  return {
    type: UPDATE_USER,
    payload: user,
  };
}

export function updateUser(user) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(updateUserActionCreator(user));
      resolve();
    });
  };
}
