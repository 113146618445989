import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const NavMenu = ({ strings, menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${
        sidebarMenu
          ? "sidebar-menu"
          : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
      } `}
    >
      <nav>
        <ul>
          <li>
            <Link to="/">{strings["home"]}</Link>
          </li>
          <li>
            <Link to="/shop">
              {/* Shop */}
              {strings["products"]}
            </Link>
          </li>
          <li>
            <Link to="/about">{strings["about_us"]}</Link>
          </li>
          <li>
            <Link to="/contact">{strings["contact_us"]}</Link>
          </li>
          {/* <li>
            <Link to="/orders">
              {" "}
              {strings["My Orders"]}
            </Link>
          </li>
          <li>
            <Link to="/wishlist">
              {strings["Saved"]}
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};

export default multilanguage(NavMenu);
