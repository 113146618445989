export const LOADING = "LOADING"

function isloading(loading) {
  return {
    type: LOADING,
    loading
  }
}

export function isloadingHandle(loading) {
  return dispatch => {
    dispatch(isloading(loading))
  }
}