import PropTypes from "prop-types";
import React, { Fragment, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import MetaTags from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { connect } from "react-redux";
// import {
//   addToWishlist,
//   deleteFromWishlist,
//   deleteAllFromWishlist
// } from "../../redux/actions/wishlistActions";
// import { addToCart } from "../../redux/actions/cartActions";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import axios from "../../axios";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateCartDataActionCreator } from "../../redux/actions/cartData";
import { getAllFavoritesActionCreator } from "../../redux/actions/favorites";
import { TranslationsContext } from "../../App";

const Wishlist = ({
  location,
  cartItems,
  currency,
  favorites,
  accessToken,
  device,
}) => {
  const { addToast } = useToasts();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = location;
  const [quantityCount] = useState(1);

  const currentLanguageCode = useSelector(
    (state) => state.multilanguage.currentLanguageCode
  );
  const strings = useContext(TranslationsContext);

  // function addToCart(productId, quantity) {
  //   if (accessToken) {
  //     let quantityCount = quantity;
  //     let productInCart = cartItems.find(
  //       (cartItem) => cartItem.item.id === productId
  //     );
  //     if (productInCart) {
  //       quantityCount = quantity + productInCart.count;
  //     }
  //     axios
  //       .post(
  //         "carts",
  //         { item_id: productId, count: quantityCount },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${accessToken}`,
  //           },
  //         }
  //       )
  //       .then((response) => {
  //         let items = response.data.data.items;
  //         dispatch(updateCartDataActionCreator(items));
  //         addToast("Product Added Successfully", {
  //           appearance: "success",
  //           autoDismiss: true,
  //         });
  //       });
  //   } else {
  //     history.push("/login-register");
  //   }
  // }

  function addToCart(productId) {
    if (accessToken) {
      let quantity = quantityCount;
      let productInCart = cartItems.find(
        (cartItem) => cartItem.item.id === productId
      );
      if (productInCart) {
        quantity = quantity + productInCart.count;
      }
      axios
        .post(
          "carts",
          { item_id: productId, count: quantity },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Accept-Language": `${currentLanguageCode === "ar" ? "ar" : ""}`,
            },
          }
        )
        .then((response) => {
          let items = response.data.data.items;
          dispatch(updateCartDataActionCreator(items));
          addToast(strings["Product Added Successfully"], {
            appearance: "success",
            autoDismiss: true,
          });
        });
    } else {
      history.push("/login-register");
    }
  }

  function deleteFromWishlist(productId) {
    axios
      .post(
        "favorites/add_remove",
        { item_id: productId, add_favorite: "false" },
        {
          headers: {
            Authorization: `Bearer ${accessToken !== ""
              ? accessToken
              : device.deviceTokenAccount.access_token
              }`,
          },
        }
      )
      .then(() => {
        dispatch(getAllFavoritesActionCreator());
      });
  }

  function deleteAllFromWishlist() {
    let requests = favorites.map((favorite) => {
      return axios.post(
        "favorites/add_remove",
        { item_id: favorite.id, add_favorite: "false" },
        {
          headers: {
            Authorization: `Bearer ${accessToken !== ""
              ? accessToken
              : device.deviceTokenAccount.access_token
              }`,
          },
        }
      );
    });
    Promise.all(requests).then(() => {
      dispatch(getAllFavoritesActionCreator());
    });
  }

  return (
    <Fragment>
      <MetaTags>
        <title>Cookieman | Wishlist</title>
        <meta name="description" content="Wishlist page of Cookieman" />
      </MetaTags>

      <BreadcrumbsItem to="/">{strings["home"]}</BreadcrumbsItem>
      <BreadcrumbsItem to={pathname}>{strings["wishlist"]}</BreadcrumbsItem>

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />
        <div className={`cart-main-area pt-90 pb-100  ${currentLanguageCode === "ar" ? "arabic-style" : ""}`}>
          <div className="container">
            {favorites && favorites.length >= 1 ? (
              <Fragment>
                <h3 className="cart-page-title">{strings["Your wishlist items"]}</h3>
                <div className="row">
                  <div className="col-12">
                    <div className="table-content table-responsive cart-table-content">
                      <table>
                        <thead>
                          <tr>
                            <th>{strings["image"]}</th>
                            <th>{strings["product name"]}</th>
                            <th>{strings["unit price"]}</th>
                            <th>{strings["Add-to-cart"]}</th>
                            <th>{strings["action"]}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {favorites.map((favorite, key) => {
                            return (
                              <tr key={key}>
                                <td className="product-thumbnail">
                                  <Link to={"/product/" + favorite.id}>
                                    <img
                                      className="img-fluid"
                                      src={favorite.images[0]}
                                      alt=""
                                    />
                                  </Link>
                                </td>

                                <td className="product-name text-center">
                                  <Link to={"/product/" + favorite.id}>
                                    {favorite.name}
                                  </Link>
                                </td>

                                <td className="product-price-cart">
                                  <span className="amount">
                                    {favorite.price +
                                      " " +
                                      strings["egp"]}
                                  </span>
                                </td>

                                <td className="product-wishlist-cart">
                                  {favorite?.quantity && favorite.quantity > 0 ?
                                    (<button
                                      onClick={() => addToCart(favorite.id)}
                                    >
                                      {strings["Add-to-cart"]}
                                    </button>) :
                                    (<button disabled>{strings["out-of-stock"]}</button>)
                                  }
                                </td>

                                <td className="product-remove">
                                  <button
                                    onClick={() =>
                                      deleteFromWishlist(favorite.id)
                                    }
                                  >
                                    <i className="fa fa-times"></i>
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="cart-shiping-update-wrapper">
                      <div className="cart-shiping-update">
                        <Link to="/shop">{strings["Continue Shopping"]}</Link>
                      </div>
                      <div className="cart-clear">
                        <button onClick={deleteAllFromWishlist}>
                          {strings["Clear Wishlist"]}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <div className="item-empty-area text-center">
                    <div className="item-empty-area__icon mb-30">
                      <i className="pe-7s-like"></i>
                    </div>
                    <div className="item-empty-area__text">
                      {strings["No items found in wishlist"]} <br />{" "}
                      <Link to={"/shop"}>{strings["Add Items"]}</Link>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

Wishlist.propTypes = {
  addToCart: PropTypes.func,
  cartItems: PropTypes.array,
  currency: PropTypes.object,
  location: PropTypes.object,
  deleteAllFromWishlist: PropTypes.func,
  deleteFromWishlist: PropTypes.func,
  wishlistItems: PropTypes.array,
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cartData,
    favorites: state.favorites.items,
    currency: state.currencyData,
    accessToken: state.access_token,
    device: state.device,
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     addToCart: (item, addToast, quantityCount) => {
//       dispatch(addToCart(item, addToast, quantityCount));
//     },
//     addToWishlist: (item, addToast, quantityCount) => {
//       dispatch(addToWishlist(item, addToast, quantityCount));
//     },
//     deleteFromWishlist: (item, addToast, quantityCount) => {
//       dispatch(deleteFromWishlist(item, addToast, quantityCount));
//     },
//     deleteAllFromWishlist: addToast => {
//       dispatch(deleteAllFromWishlist(addToast));
//     }
//   };
// };

export default connect(mapStateToProps)(Wishlist);
