export const FB_LOADED = "FB_LOADED";

function fbInit(isFbLoaded) {
  return {
    type: FB_LOADED,
    facebook: isFbLoaded,
  };
}

export function getFbSdk() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      (function (d, s, id) {
        var js,
          fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");

      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "216515926975870",
          cookie: false,
          xfbml: false,
          version: "v11.0",
        });
        window.FB.AppEvents.logPageView();
        dispatch(fbInit(true));
        return resolve();
      };
    });
  };
}
