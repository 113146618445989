import axios from "axios";

const instane = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY,
    "Content-Type": "application/json",
    // "Accept-Language": "ar",
  },
});

export default instane;
