import axios from "../../axios";

export const GET_ALL_FAVORITES = "GET_ALL_FAVORITES";

function getAllFavoritesAction(favorites) {
  return {
    type: GET_ALL_FAVORITES,
    payload: favorites,
  };
}

export function getAllFavoritesActionCreator() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let deviceToken = getState().device.deviceTokenAccount?.access_token;
      let accessToken = getState().access_token;
      axios
        .get("favorites", {
          params: {
            per_page: 100,
          },
          headers: {
            Authorization: `Bearer ${
              accessToken !== "" ? accessToken : deviceToken
            }`,
          },
        })
        .then((response) => {
          let data = response.data.data;
          dispatch(getAllFavoritesAction(data));
          resolve();
        })
        .catch((err) => {
          console.log(err);
          dispatch(getAllFavoritesAction({}));
          reject();
        });
    });
  };
}

export function getAllArabicFavoritesActionCreator() {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let deviceToken = getState().device.deviceTokenAccount?.access_token;
      let accessToken = getState().access_token;
      axios
        .get("favorites", {
          params: {
            per_page: 100,
          },
          headers: {
            Authorization: `Bearer ${
              accessToken !== "" ? accessToken : deviceToken
            }`,
            "Accept-Language": "ar",
          },
        })
        .then((response) => {
          let data = response.data.data;
          dispatch(getAllFavoritesAction(data));
          resolve();
        })
        .catch((err) => {
          console.log(err);
          dispatch(getAllFavoritesAction({}));
          reject();
        });
    });
  };
}
