import { GET_USER_DATA, AUTOMATE_USER_LOGIN, UPDATE_USER_ADDRESS, ADD_USER_ADDRESS, UPDATE_USER, GET_ALL_ADDRESS } from '../actions/userData'


export default function accessToken(state = {}, action) {
  switch (action.type) {
    case GET_USER_DATA:
      return action.payload
    case AUTOMATE_USER_LOGIN:
      return action.payload
    case UPDATE_USER_ADDRESS:
      return { ...state, addresses: action.payload }
    case ADD_USER_ADDRESS:
      return { ...state, addresses: [...state.addresses, action.payload] }
    case UPDATE_USER:
      return { ...state, user: action.payload }
    case GET_ALL_ADDRESS:
      let oldAddresses = state.addresses ?? []
      return { ...state, addresses: [...oldAddresses, ...action.payload] }
    default:
      return state
  }
}