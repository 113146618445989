import axios from "../../axios";
export const GET_AREAS = "GET_AREAS";

function getAreas(cities) {
  return {
    type: GET_AREAS,
    payload: cities,
  };
}

export function getAreasActionCreator(city_id) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      let accessToken = getState().access_token;
      if (accessToken) {
        axios
          .get(`city/${city_id}/areas`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            let data = response.data;
            dispatch(getAreas(data));
            return resolve();
          })
          .catch((err) => {
            console.log(err);
            dispatch(getAreas({}));
            return reject();
          });
      }
      dispatch(getAreas({}));
      resolve();
    });
  };
}
