import axios from "../../axios";
// import { isloadingHandle } from './loading'
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";
export const FILTER_PRODUCTS = "FILTER_PRODUCTS";

const fetchProductsSuccess = (products) => ({
  type: FETCH_PRODUCTS_SUCCESS,
  products,
});

// fetch products
export function fetchProducts(categoryId = "", page = 1) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // console.log(categoryId, page);
      let accessToken = getState().device.deviceTokenAccount?.access_token;
      if (accessToken) {
        if (categoryId !== "") {
          axios
            .get(`categories/${categoryId}/items`, {
              params: {
                page,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((response) => {
              let data = response.data.data;
              dispatch(fetchProductsSuccess(data));
              return resolve();
            })
            .catch((err) => {
              console.log(err);
              return reject();
            });
        } else {
          axios
            .get("items", {
              params: {
                page,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((response) => {
              let data = response.data.data;
              dispatch(fetchProductsSuccess(data));
              return resolve();
            })
            .catch((err) => {
              console.log(err);
              return reject();
            });
        }
      }
      dispatch(fetchProductsSuccess({}));
      return resolve();
    });
  };
}

export function fetchArabicProducts(categoryId = "", page = 1) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // console.log(categoryId, page);
      let accessToken = getState().device.deviceTokenAccount?.access_token;
      if (accessToken) {
        if (categoryId !== "") {
          axios
            .get(`categories/${categoryId}/items`, {
              params: {
                page,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Accept-Language": "ar",
              },
            })
            .then((response) => {
              let data = response.data.data;
              dispatch(fetchProductsSuccess(data));
              return resolve();
            })
            .catch((err) => {
              console.log(err);
              return reject();
            });
        } else {
          axios
            .get("items", {
              params: {
                page,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Accept-Language": "ar",
              },
            })
            .then((response) => {
              let data = response.data.data;
              dispatch(fetchProductsSuccess(data));
              return resolve();
            })
            .catch((err) => {
              console.log(err);
              return reject();
            });
        }
      }
      dispatch(fetchProductsSuccess({}));
      return resolve();
    });
  };
}

// function filterProducts(products) {
//   return {
//     type: FILTER_PRODUCTS,
//     products
//   }
// }

// // filter products
// export function filterProductsHandle(categoryId, page = 1) {
//   return (dispatch, getState) => {
//     if (categoryId !== "") {
//       let accessToken = getState().device?.deviceTokenAccount?.access_token
//       axios.get(`categories/${categoryId}/items`, {
//         params: {
//           page
//         },
//         headers: {
//           Authorization: `Bearer ${accessToken}`
//         }
//       })
//         .then(response => {
//           let data = response.data.data
//           console.log(data)
//           return dispatch(filterProducts(data))
//         })
//     }

//     return dispatch(filterProducts({}))

//   }
// }
