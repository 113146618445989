import { INIT } from '../actions/shared'
import { UPDATE_LOGIN_STATUS } from '../actions/isLogged'


export default function isLogged(state = false, action) {
  switch (action.type) {
    case INIT:
      return action.isLogged
    case UPDATE_LOGIN_STATUS:
      return action.payload
    default:
      return state
  }
}