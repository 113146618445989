import { GET_CART_DATA, UPDATE_CART_DATA } from '../actions/cartData'


export default function favorites(state = [], action) {
  switch (action.type) {
    case GET_CART_DATA:
      return action.payload
    case UPDATE_CART_DATA:
      return action.payload
    default:
      return state
  }
}