import axios from "../../axios";
export const FETCH_BEST_PRODUCTS_SUCCESS = "FETCH_BEST_PRODUCTS_SUCCESS";
export const FILTER_PRODUCTS = "FILTER_BEST_PRODUCTS";

const fetchBestProductsSuccess = (bestProducts) => ({
  type: FETCH_BEST_PRODUCTS_SUCCESS,
  bestProducts,
});

// fetch products
export function fetchBestProducts(categoryId = "", page = 1) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // console.log(categoryId, page);
      let accessToken = getState().device.deviceTokenAccount?.access_token;
      if (accessToken) {
        if (categoryId !== "") {
          axios
            .get(`categories/${categoryId}/items`, {
              params: {
                page,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((response) => {
              let data = response.data.data;
              dispatch(fetchBestProductsSuccess(data));
              return resolve();
            })
            .catch((err) => {
              console.log(err);
              return reject();
            });
        } else {
          axios
            .get("items", {
              params: {
                page,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((response) => {
              let data = response.data.data;
              dispatch(fetchBestProductsSuccess(data));
              console.log("BestPRoducts", data);
              return resolve();
            })
            .catch((err) => {
              console.log(err);
              return reject();
            });
        }
      }
      dispatch(fetchBestProductsSuccess({}));
      return resolve();
    });
  };
}

export function fetchArabicBestProducts(categoryId = "", page = 1) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      // console.log(categoryId, page);
      let accessToken = getState().device.deviceTokenAccount?.access_token;
      if (accessToken) {
        if (categoryId !== "") {
          axios
            .get(`categories/${categoryId}/items`, {
              params: {
                page,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Accept-Language": "ar",
              },
            })
            .then((response) => {
              let data = response.data.data;
              dispatch(fetchBestProductsSuccess(data));
              return resolve();
            })
            .catch((err) => {
              console.log(err);
              return reject();
            });
        } else {
          axios
            .get("items", {
              params: {
                page,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            })
            .then((response) => {
              let data = response.data.data;
              dispatch(fetchBestProductsSuccess(data));
              console.log("BestPRoducts", data);
              return resolve();
            })
            .catch((err) => {
              console.log(err);
              return reject();
            });
        }
      }
      dispatch(fetchBestProductsSuccess({}));
      return resolve();
    });
  };
}
