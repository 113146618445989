export const UPDATE_FCM_TOKEN = "UPDATE_FCM_TOKEN"


function updateFcmToken(token) {
  return {
    type: UPDATE_FCM_TOKEN,
    token
  }
}

export function handleUpdateFcmToken(token) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(updateFcmToken(token))
      resolve()
    })
  }
}