import React, { Children, Fragment } from 'react'
import { useSelector } from 'react-redux'



function LoadingContainer({ children }) {
  // const isLoading = useSelector(state => state.isLoading)
  // if (!isLoading) {
  //   return (
  //     <Fragment>
  //       {Children.only(children)}
  //     </Fragment>
  //   )
  // }
  return (
    <div className="flone-preloader-wrapper">
      <div className="flone-preloader">
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default LoadingContainer