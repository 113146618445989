export const UPDATE_LOGIN_STATUS = "UPDATE_LOGIN_STATUS"


function isLoggedAction(isLogged) {
  return {
    type: UPDATE_LOGIN_STATUS,
    payload: isLogged
  }
}

export function updateLoginStatus(isLogged) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(isLoggedAction(isLogged))
      resolve()
    })
  }
}