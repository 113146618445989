import { INIT } from '../actions/shared'
import { GET_ACCOUNT_ACCESS_TOKEN } from '../actions/accountToken'


export default function accessToken(state = "", action) {
  switch (action.type) {
    case INIT:
      return action.access_token
    case GET_ACCOUNT_ACCESS_TOKEN:
      return action.payload
    default:
      return state
  }
}