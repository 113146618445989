export const INIT = "INIT"

function init(isLogged, access_token) {
  return {
    type: INIT,
    isLogged,
    access_token
  }
}

export function initHandle() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      let access_token = localStorage.getItem("access_token")
      if (access_token) {
        dispatch(init(true, access_token))
        return resolve()
      }
      dispatch(init(false, ""))
      return resolve()
    })
  }
} 