import { FETCH_PRODUCTS_SUCCESS } from "../actions/productActions";
// import { FILTER_PRODUCTS } from "../actions/productActions"

function productReducer(state = {}, action) {
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      // let prevItems = state?.items ?? []
      // let newItems = action?.products?.items ?? []
      return { ...action?.products };
    // case FILTER_PRODUCTS:
    //   return state
    default:
      return state;
  }
}

export default productReducer;
