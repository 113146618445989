import PropTypes from "prop-types";
import React, { Fragment , useState , useEffect } from "react";
import { Link , useHistory} from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import axios from "../../../axios";
import { useDispatch, useSelector } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { updateCartDataActionCreator } from "../../../redux/actions/cartData";
import {updateCartSubTotal} from "../../../redux/actions/cartSubTotal";

const MenuCart = ({ cartData, currency, strings }) => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.access_token);
  const [subTotal, setSubTotal] = useState(0);
  const history = useHistory();
  

  function deleteFromCart(productId) {
    axios
      .post(
        "carts",
        { item_id: productId, count: 0 },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        let items = response.data.data.items;
        dispatch(updateCartDataActionCreator(items));
        addToast(strings["Cart Item Removed Successfully"], {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    if (cartData.length > 0) {
      let newSubTotal = cartData
        .reduce((accumulator, cartData) => {
          return accumulator + (cartData.item.price * cartData.count);
        }, 0)
        .toFixed(2);
      setSubTotal(newSubTotal);
      dispatch(updateCartSubTotal(newSubTotal));
    }
  }, [cartData , dispatch]);

  function proceedToCheckout() {
    if (subTotal >= 500) {
      history.push("/checkout");
    }
     else {addToast(strings["Minimum Order 500 LE"], {
      appearance: "warning",
      autoDismiss: true,
      autoDismissTimeout: 5000,
    });}
  }


  return (
    <div className="shopping-cart-content">
      {cartData && cartData.length > 0 ? (
        <Fragment>
          <ul>
            {cartData.map((single, key) => {
              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={"/product/" + single.item.id}>
                      <img
                        alt=""
                        src={single.item.images[0]}
                        className="img-fluid"
                      />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link to={"/product/" + single.item.id}>
                        {" "}
                        {single.item.name}{" "}
                      </Link>
                    </h4>
                    <h6>
                      <span>{strings["qty"]}</span>
                      <span> : </span>
                      <span>{single.count}</span>
                    </h6>
                    <span className="price-cart-menu">
                      {/* {single.item.price + " " + currency.currencySymbol} */}
                      <span>{single.item.price}</span>
                      <span> {strings["egp"]} </span>
                    </span>
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => deleteFromCart(single.item.id)}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4 className="hide-this-in-arabic">
              Total :{" "}
              <span className="shop-total">
                {cartData.reduce((accumulator, currentValue) => {
                  return (
                    accumulator + currentValue.item.price * currentValue.count
                  );
                }, 0) +
                  " " +
                  currency.currencySymbol}
              </span>
            </h4>

            <h4 className="hide-default-show-in-arabic">
              <span className="shop-total-arabic">
                <span>
                  {cartData.reduce((accumulator, currentValue) => {
                    return (
                      accumulator + currentValue.item.price * currentValue.count
                    );
                  }, 0)}
                </span>
                <span className="pound">جنيه</span>
              </span>
              <span> :الإجمالي</span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to="/cart">
              {strings["view-cart"]}
            </Link>
            <button className="default-btn" onClick={proceedToCheckout}>
              {strings["checkout"]}
            </button>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">{strings["no-cart-items"]}</p>
      )}
    </div>
  );
};

MenuCart.propTypes = {
  cartData: PropTypes.array,
  currency: PropTypes.object,
  strings: PropTypes.object,
};

// export default MenuCart;
export default multilanguage(MenuCart);
