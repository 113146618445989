export const GOOGLE_LOADED = "GOOGLE_LOADED"


function googleInit(isGoogleLoaded, googleAuth) {
  return {
    type: GOOGLE_LOADED,
    google: isGoogleLoaded,
    googleAuth
  }
}

export function getGoogleSdk() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script')
      script.src = "https://apis.google.com/js/platform.js"
      script.id = "google-sdk"
      script.async = true
      script.defer = true
      document.body.appendChild(script)
      script.onload = () => {
        window.gapi.load('auth2', () => {
          window.gapi.auth2.init()
            .then(() => {
              let googleAuthObj = window.gapi.auth2.getAuthInstance()
              dispatch(googleInit(true, googleAuthObj))
              resolve()
            })
            .catch(err => {
              console.log(err)
              reject()
            })
        });
      }
    })
  }
}